import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './team-building.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { Card } from 'react-bootstrap';

const HSRGuidesTeamBuilding: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Team building</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_team.png"
            alt="Team building"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Team building</h1>
          <h2>Struggling with creating a good team? Look no further!</h2>
          <p>
            Last updated: <strong>24/01/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Introduction" />
        <p>
          As a turn based RPG game, team building is an extremely important
          aspect of the combat gameplay in Honkai: Star Rail. This guide aims to
          provide some general recommendations and tips for how to build
          effective teams in Honkai: Star Rail. If you are interested in team
          recommendations for specific Characters, be sure to check that
          Character’s page on the website for a more detailed breakdown.
        </p>
        <p>
          When building a team, take the following factors into consideration.
        </p>
        <ul>
          <li>Team Playstyle</li>
          <li>Enemy Elemental Weaknesses</li>
          <li>Team Synergy</li>
          <li>Skill Point Utilization</li>
        </ul>
        <SectionHeader title="Team Playstyle" />
        <p>
          The first thing to think about when building a team is what kind of
          playstyle do you want to build a team around. Below are some general
          team playstyles for consideration:
        </p>
        <h5>Aggressive/Proactive Build</h5>
        <p>
          Focuses on the offensive with the goal of taking out the enemies as
          fast as possible. Teams in this playstyle will focus on one Main
          Damage Dealer, often called a hyper carry, that is supported by at
          least two Offensive Supports who are focused on increasing the hyper
          carry’s damage as much as possible via offensive buffs on the hyper
          carry or inflicting debuffs on the enemies that would cause them to
          take more damage.
        </p>
        <h5>Defensive/Reactive Build</h5>
        <p>
          Focuses on keeping the team alive while whittling away at the enemy.
          This type of team will generally run multiple Defensive Supports that
          provide your team with sustain in the form of either healing and/or
          shields while relying on a damage dealer that can function reasonably
          well with minimal or no support to whittle away at the enemy’s HP.
          Some teams will also utilize crowd control effects such as freezing or
          imprisoning the enemy to further increase the team’s survivability.
        </p>
        <p>
          Another thing to keep in mind while building your team is how to keep
          your team members alive. It is recommended that you use at least one
          healer or shielder in order to mitigate the damage that your team will
          be taking. There are plenty of hard hitting enemies and enemies with
          AoE attacks that can and will take down your team if you are not
          careful, especially early on in the game when your team is
          underleveled and undergeared.
        </p>
        <SectionHeader title="Enemy Elemental Weaknesses" />
        <p>
          After you have come up with the game plan for your team, the next
          thing to consider is what elemental weaknesses the enemy has.
        </p>
        <p>
          Using Characters whose element the enemy is the weak to will provide a
          considerable advantage during combat as one of the core combat
          mechanics in Honkai: Star Rail revolves around dealing damage of the
          elemental type that the enemy is weak to in order to break their
          Toughness bar. Breaking the enemy’s Toughness bar serves both to
          increase your team’s damage as well as delaying that enemy’s turn. On
          top of these effects, enemies are also more susceptible to damage from
          elements that they are weak against. When combined together, these
          effects make teams that can exploit the enemy’s elemental weaknesses
          far more effective than teams that cannot.
        </p>
        <p>
          Note that you do not need to have every Character in your team be of
          an element that the enemy is weak against. Generally speaking,
          Characters who are good at breaking the enemy’s Toughness bar
          (Breakers) should be of an element that the enemy is weak against.
          This will typically be your damage dealer(s), though some supporting
          Characters, such as Asta and the Fire Trailblazer, are also effective
          in this role. Besides these Breakers, the rest of your team do not
          necessarily need to be of an element that the enemy is weak against.
        </p>
        <p>
          Another thing to note is that all enemies in this game have multiple
          elemental weaknesses, so it is not necessary to build a mono-element
          team. 2, 3, and even 4 element teams can function perfectly fine if
          you have the enemy’s elemental weaknesses sufficiently covered.
        </p>
        <SectionHeader title="Team Synergy" />
        <p>
          After elemental weaknesses, the next thing to consider is to pick
          Characters for your team that have synergies with one another. Think
          about how the Characters in your team can complement each other,
          whether that be shoring up each other's weaknesses, enabling new
          approaches to combat, or enhancing your Characters’ combat abilities
          to the next level. Alternatively, it is also important to take in
          account any anti-synergies that Characters may have. Some Characters
          just do not work well together in a team and even if they are strong
          individually, it may be a better idea to put them on different teams,
          so that their respective strengths can be better utilized.
        </p>
        <p>
          Sources of synergies come in many forms, but mainly will come from how
          the Characters’ kits interact with one another. It can also come from
          the gear (Light Cones and Relics/Planetary Ornaments) that they wear
          as well.{' '}
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Skill Point Utilization" />
        <p>
          Skills Points is a key resource that you will need to manage
          effectively in order to bring out the full potential of your team.
          Every Character in your team shares one Skill Point pool, so it is
          important to factor in what your Skill Point generation and
          consumption looks like when you are building your team. Aim for
          building a team that is at least Skill Point neutral. Skill Point
          neutral means that you are generating Skill Points at the rate that
          you are consuming them. Being Skill Point neutral will allow your
          damage dealer(s) to function at their best since it means that they
          will be able to use their higher damaging ability (which is typically
          their Skill) every turn.{' '}
        </p>
        <p>
          Generally speaking, damage dealing Characters tend to be Skill Point
          negative (consumes more Skill Points than they generate) while
          Defensive Supports tend to be Skill Point positive (generates more
          Skill Points than they consume). Offensive Supports are a mixed bag
          when it comes to the Skill Point economy as they can be Skill Point
          positive, neutral, or negative depending on how you use them.
        </p>
        <SectionHeader title="Team Compositions" />
        <h5>General / Defensive</h5>
        <ul>
          <li>Main damage dealer</li>
          <li>Offensive support</li>
          <li>Defensive support</li>
          <li>Flex slot</li>
        </ul>
        <p>
          As the name suggests, this team composition serves as the general
          template when it comes to team building and is capable of handling
          most if not all of the combat challenges the game has. If you are
          unsure about how to build a team, you can never go wrong with using
          this team composition as your starting point and then make changes to
          it as you see fit.
        </p>
        <p>
          This team takes a balanced approach to combat by using one Character
          as the main damage dealer who is then supported by the rest of the
          team with buffs and sustain (shields and heals). While this will
          result in slower clear time compared to Hypercarry od Dual carry
          compositions, it's much safer and easier to play, especially in the
          early game when you lack good Relics. But even later down the road,
          this composition is the go-to one when you need to conquer the hardest
          content in the game (like Swarm Disaster) as without double Sustain
          characters, you will often die on boss fights.
        </p>
        <h6>Example Team Compositions</h6>
        <div className="custom-team-showcase">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="qingque"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Offensive support</h5>
            <div className="multi">
              <Card className="avatar-card">
                <HSRCharacter
                  slug="tingyun"
                  mode="icon"
                  showLabel
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Healer</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="natasha"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Shielder</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="march-7th"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
        </div>
        <h5>Hyper Carry</h5>
        <ul>
          <li>Main damage dealer</li>
          <li>Offensive support</li>
          <li>Offensive support</li>
          <li>Flex slot</li>
        </ul>
        <p>
          This team composition is a variant of the Generalist team that focuses
          on buffing up one damage dealer (the hyper carry) who will be the
          primary source of damage for the team. A hyper carry team will
          generally run two Offensive Supports and one Defensive Support to
          provide a high amount of damage buffs for the hyper carry while still
          maintaining a certain degree of survivability.
        </p>
        <p>
          While it is possible to replace the Defensive Support slot with a
          third Offensive Support for a pure glass cannon team, it is not
          recommended to do so unless you are absolutely confident that you can
          win the battle quickly enough to not have to worry about your team’s
          survivability.{' '}
        </p>
        <h6>Example Hyper Carry Team Compositions</h6>
        <div className="custom-team-showcase">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="seele"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Offensive support</h5>
            <div className="multi">
              <Card className="avatar-card">
                <HSRCharacter
                  slug="tingyun"
                  mode="icon"
                  showLabel
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Offensive support</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="silver-wolf"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Sustain</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="fu-xuan"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
        </div>
        <p>
          A single target focused hyper carry team that revolves around pumping
          up Seele’s damage with Tingyun and Silver Wolf. This team will want to
          focus down targets one by one before moving on to the next target.{' '}
        </p>
        <div className="custom-team-showcase">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="jingliu"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Offensive support</h5>
            <div className="multi">
              <Card className="avatar-card">
                <HSRCharacter
                  slug="bronya"
                  mode="icon"
                  showLabel
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
          </div>
          <div className="custom-team-tab">
            <h5 className="off-support">Offensive support</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="pela"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Healer</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="luocha"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
        </div>
        <p>
          This hyper carry team places an emphasis on Blast attacks while also
          being capable of dealing out great damage to single targets. Bronya
          and Pela amplify Jingliu's damage - the first will allow her to attack
          more often and Pela will shred the enemies' DEF, making Jingliu's
          attacks do more damage.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesTeamBuilding;

export const Head: React.FC = () => (
  <Seo
    title="Team building | Honkai: Star Rail | Prydwen Institute"
    description="Struggling with creating a good team? Look no further!"
  />
);
